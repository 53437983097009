import mapboxgl from 'mapbox-gl'
import uuid from 'uuid'
import travelPoints from './data/travel.json'
import alza from './static/alza.png'
import gate from './static/gate.png'
import macdonalds from './static/macdonalds.png'
import relay from './static/relay.png'
import starbucks from './static/starbucks.png'
import tesco from './static/tesco.png'
import vangraaf from './static/van_graaf.png'
import { Coords, CoordsWithExpense } from './types'

export function getDistanceBetweenCoordsInKm(coord1: Coords, coord2: Coords) {
  const { lat: lat1, lng: lng1 } = coord1
  const { lat: lat2, lng: lng2 } = coord2

  const R = 6371 // km
  const dLat = ((lat2 - lat1) * Math.PI) / 180
  const dLon = ((lng2 - lng1) * Math.PI) / 180

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c

  return d
}

export const mapMerchantToImageUrl = (merchant: string): string | undefined => {
  switch (merchant.toLowerCase()) {
    case 'tesco':
      return tesco
    case 'alza':
      return alza
    case 'vangraaf':
      return vangraaf
    case 'starbucks':
      return starbucks
    case 'macdonalds':
      return macdonalds
    case 'gate':
      return gate
    case 'relay':
      return relay
    default:
      return undefined
  }
}

export const getTransformedTravelPoints = (): CoordsWithExpense[] => {
  return travelPoints.map(point => {
    const { coords, ...other } = point
    const [lat, lng] = coords
    return { ...other, lat, lng, id: uuid.v4() }
  }) as CoordsWithExpense[]
}

export const convertHoursToMillis = (hours: number) => hours * 3600000

// This method projects point with lng and lat properties to px relative to map container
export const projectPoint = (map: mapboxgl.Map, input: Coords) => {
  const { lat, lng } = input
  const point = map.project(new mapboxgl.LngLat(lng, lat))
  return [point.x, point.y]
}

export const projectPointForTooltip = (map: mapboxgl.Map, input: Coords) => {
  const [x, y] = projectPoint(map, input)
  // This will translate tooltip so tip will point to exact coord
  return [x - 27.5, y - 47.5]
}

export const moveMapToPoint = (map: mapboxgl.Map, pt: Coords) => {
  map.jumpTo({ center: [pt.lng, pt.lat] })
}

export const appendPxToNumber = (num: number): string => `${num}px`

export const getLayerData = (coordinates: number[][]): GeoJSON.Feature<GeoJSON.Geometry> => {
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates,
    },
  }
}

export const mapPointToLatLng = (pt: Coords) => [pt.lng, pt.lat]

export const emptyArray = <T extends object>(arr: T[]) => arr.splice(0, arr.length)
