import TWEEN from '@tweenjs/tween.js'
import * as d3 from 'd3'
import mapboxgl, { GeoJSONSource } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { CoordsWithExpense, Dimensions } from './types.js'
import {
  convertHoursToMillis,
  getDistanceBetweenCoordsInKm,
  getTransformedTravelPoints,
  mapMerchantToImageUrl,
  moveMapToPoint,
  projectPointForTooltip,
  appendPxToNumber,
  getLayerData,
  mapPointToLatLng,
  emptyArray,
} from './utils'

const TRAVEL_SPEED_KMPH = 80
const LINE_SOURCE_ID = 'route-line'

const listOfTravelPoints = getTransformedTravelPoints()
const travelHistory: CoordsWithExpense[] = []

mapboxgl.accessToken = 'pk.eyJ1IjoicGFuemVsdmEiLCJhIjoiY2swaHIzOGY0MDU1NjNjbXBmMDloZHdvdyJ9.I6p8_Ic4HbBK1meBeUsanA'

const colors = {
  lineColor: '#4662f4',
}

const rerenderGeoJsonLine = (map: mapboxgl.Map) => {
  const coordinates = [...travelHistory.map(mapPointToLatLng), mapPointToLatLng(map.getCenter())]

  const source = map.getSource(LINE_SOURCE_ID) as GeoJSONSource
  source.setData(getLayerData(coordinates))
}

// This method adds 3d buildings to map
// Docs ref: https://docs.mapbox.com/mapbox-gl-js/example/3d-buildings/
const addBuildingsToMap = (map: mapboxgl.Map) => {
  const layers = map.getStyle().layers
  if (!layers) {
    return
  }

  let labelLayerId
  for (const layer of layers) {
    // @ts-ignore
    if (layer.type === 'symbol' && layer.layout['text-field']) {
      labelLayerId = layer.id
      break
    }
  }

  map.addLayer(
    {
      id: '3d-buildings',
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      type: 'fill-extrusion',
      minzoom: 15,
      paint: {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
        'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
        'fill-extrusion-opacity': 0.6,
      },
    },
    labelLayerId,
  )
}

// This method adds line of movement to map
// Docs ref: https://docs.mapbox.com/mapbox-gl-js/example/geojson-line/
const addLineToMap = (map: mapboxgl.Map) => {
  // in case the line needs to be smooth we can (in theory) use turfjs library
  // docs https://turfjs.org/docs/#bezierSpline
  // const data = bezierSpline({ type: 'LineString', coordinates: coordinates }, { sharpness: 0.5 }) as any
  map.addLayer({
    id: LINE_SOURCE_ID,
    type: 'line',
    source: {
      type: 'geojson',
      data: getLayerData([]),
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': colors.lineColor,
      'line-width': 8,
    },
  })
}

const connectTweens = (tweens: TWEEN.Tween[]) => {
  for (let i = 0; i < tweens.length - 1; i++) {
    const tween = tweens[i]
    const nextTween = tweens[i + 1]
    tween.chain(nextTween)
  }

  // Cycle animation - last tween will be followed by first
  tweens[tweens.length - 1].chain(tweens[0])
}

const changeTooltipOpacity = (opacity: number, index: number) => {
  d3.selectAll(`#expense-${index}`).style('opacity', opacity)
}

const addPointToHistory = (id: string) => {
  if (!!travelHistory.find(el => el.id === id)) {
    return
  }

  const pt = listOfTravelPoints.find(el => el.id === id)
  if (!pt) {
    return
  }

  travelHistory.push(pt)
}

const buildTimeline = (coords: CoordsWithExpense[], map: mapboxgl.Map) => {
  const tweens: TWEEN.Tween[] = []

  for (let i = 0; i < coords.length - 1; i++) {
    const currentCoord = coords[i]
    const nextCoord = coords[i + 1]

    const hours = getDistanceBetweenCoordsInKm(currentCoord, nextCoord) / TRAVEL_SPEED_KMPH
    const milliseconds = convertHoursToMillis(hours)

    const movementTween = new TWEEN.Tween({ lng: currentCoord.lng, lat: currentCoord.lat })
      .to({ lat: nextCoord.lat, lng: nextCoord.lng }, milliseconds)
      .onUpdate(pt => {
        moveMapToPoint(map, pt)
        addPointToHistory(currentCoord.id)
      })

    if (!currentCoord.expense) {
      tweens.push(movementTween)
      continue
    }

    const expenseIndex = coords.slice(0, i).filter(coord => coord.expense).length

    const tooltipTweens = [
      // show tooltip
      new TWEEN.Tween({ opacity: 0 })
        .to({ opacity: 1 }, 300)
        .onUpdate(({ opacity }) => changeTooltipOpacity(opacity, expenseIndex))
        .easing(TWEEN.Easing.Quadratic.In),
      // wait time
      // @ts-ignore
      new TWEEN.Tween(null).to(null, 1300),
      // hide tooltip
      new TWEEN.Tween({ opacity: 1 })
        .to({ opacity: 0 }, 300)
        .onUpdate(({ opacity }) => changeTooltipOpacity(opacity, expenseIndex))
        .easing(TWEEN.Easing.Quadratic.Out),
    ]

    tweens.push(...tooltipTweens, movementTween)
  }

  return tweens
}

const createHtmlLayer = (parent: HTMLElement, dimesions: Dimensions) => {
  const { width, height } = dimesions

  return d3
    .select(parent)
    .append('div')
    .style('position', 'absolute')
    .style('width', appendPxToNumber(width))
    .style('height', appendPxToNumber(height))
}

const initializeD3 = (map: mapboxgl.Map, mapWrapper: HTMLElement) => {
  const bcr = mapWrapper.getBoundingClientRect()
  const { width, height } = bcr

  const canvas = map.getCanvasContainer()

  // User circle
  const userCircleRadius = 40
  const foOffset = 10

  createHtmlLayer(canvas, { width, height })
    .append('div')
    .style('position', 'absolute')
    .style('left', appendPxToNumber(width / 2 - userCircleRadius / 2 - foOffset))
    .style('top', appendPxToNumber(height / 2 - userCircleRadius / 2 - foOffset))
    .style('width', appendPxToNumber(100))
    .style('height', appendPxToNumber(100))
    .append('div')
    .attr('class', 'user-wrapper')
    .append('div')
    .attr('class', 'user')

  const svg = createHtmlLayer(canvas, { width, height })

  // expense tooltip things
  const expenseBox = svg
    .selectAll()
    .data(listOfTravelPoints.filter(d => d.expense))
    .enter()
    .append('div')
    .style('position', 'absolute')
    .style('left', d => appendPxToNumber(projectPointForTooltip(map, d)[0]))
    .style('top', d => appendPxToNumber(projectPointForTooltip(map, d)[1]))
    .style('width', appendPxToNumber(300))
    .style('height', appendPxToNumber(200))
    .style('opacity', '0')
    .attr('class', 'expense')
    .attr('id', (_, i) => `expense-${i}`)
    .append('div')
    .attr('class', 'expense-box')

  expenseBox
    .append('img')
    .attr('class', 'expense-box-image')
    .attr('src', d => mapMerchantToImageUrl(d.expense ? d.expense.merchant : '') || null)
  expenseBox.append('div').attr('class', 'expense-box-divider')
  expenseBox
    .append('div')
    .attr('class', 'expense-box-price')
    .text(d => {
      if (!d.expense) {
        return ''
      }

      const { amount, currency } = d.expense
      return `${amount} ${currency}`
    })

  // Main tween animation loop
  const animate = (time: number) => {
    requestAnimationFrame(animate)
    TWEEN.update(time)
  }
  requestAnimationFrame(animate)

  const tweens = buildTimeline(listOfTravelPoints, map)
  connectTweens(tweens)

  tweens[tweens.length - 1].onComplete(() => emptyArray(travelHistory))

  // Start animation
  tweens[0].start()
}

export const createTravelMap = (container: HTMLElement | null) => {
  if (!container) {
    return
  }

  const map = new mapboxgl.Map({
    container,
    center: listOfTravelPoints[0],
    style: 'mapbox://styles/mapbox/light-v9',
    zoom: 16.5,
    pitch: 20,
    antialias: true,
    interactive: false,
  })

  map.on('load', () => {
    addLineToMap(map)
    addBuildingsToMap(map)
    initializeD3(map, container)
  })

  map.on('move', () => {
    d3.selectAll('.expense')
      .style('left', (d: any) => appendPxToNumber(projectPointForTooltip(map, d)[0]))
      .style('top', (d: any) => appendPxToNumber(projectPointForTooltip(map, d)[1]))

    rerenderGeoJsonLine(map)
  })
}
