import React, { FC, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { createTravelMap } from './travel'

const Container = styled.div`
  margin: 40px;
  margin-top: 10px;
  height: 500px;
  width: 800px;

  .expense-box {
    position: relative;

    padding-left: 10px;
    padding-right: 10px;
    width: 160px;
    height: 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #ffffff;
    border-radius: 4px;

    font-family: 'Poppins', sans-serif;
    font-size: 16px;

    filter: drop-shadow(0 4px 6px rgba(50, 50, 93, 0.14)) drop-shadow(0 1px 3px rgba(0, 0, 0, 0.11));

    z-index: 20;
  }

  .expense-box:before {
    content: '';

    position: absolute;
    bottom: 0;
    left: 20px;

    width: 15px;
    height: 15px;

    transform: translateY(50%) rotate(45deg);
    border-radius: 0 0 4px 0;

    background-color: #ffffff;

    z-index: -1;
  }

  .expense-box-divider {
    height: 70%;
    width: 1px;

    background-color: #eeeeee;
  }

  .expense-box-price {
    max-width: 75px;
  }

  .expense-box-image {
    max-height: 40px;
    max-width: 75px;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(70, 98, 244, 0.3);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(70, 98, 244, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(70, 98, 244, 0);
    }
  }

  .user-wrapper {
    position: absolute;

    /* foOffset */
    top: 10px;
    left: 10px;

    width: 40px;
    height: 40px;

    border-radius: 20px;

    transform: rotateX(40deg);

    animation: pulse 2s infinite;
  }

  .user {
    width: 40px;
    height: 40px;

    position: relative;

    background-color: rgba(70, 98, 244, 0.3);
    border-radius: 20px;
  }

  .user::before {
    content: '';
    position: absolute;

    top: 5px;
    left: 5px;

    width: 30px;
    height: 30px;

    background-color: rgba(70, 98, 244, 1);
    border-radius: 15px;
  }
`

const Timemap: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    createTravelMap(ref.current)
  }, [])

  return <Container ref={ref}></Container>
}

export default Timemap
